<template>
  <!-- MODAL CONFIRM PLACE IN QUERY -->
  <b-modal
    id="confirm-in-query"
    ref="confirm-in-query"
    size="sm"
    :title="$t('booking.noFreeTime')"
    :ok-title="$t('table.yes')"
    :cancel-title="$t('table.no')"
    auto-focus-button="ok"
    @ok="$emit('confirmInQuery')"
  >
    <p class="my-2">
      {{ $t('booking.noFreeTimePutInQueue') }}
    </p>
  </b-modal>
  <!-- MODAL CONFIRM PLACE IN QUERY -->
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'ModalConfirmInQuery',
  computed: {
    ...mapGetters({
      isLocale: 'isLocale',
      isThemeHeader: 'isTheme',
    }),
  },
  methods: {
    hideModalConfirmInQuery() {
      this.$refs['confirm-in-query'].hide();
    },
  },
};
</script>