<template>
  <!-- MODAL CONFIRM WITHOUT PREMIUM POSITION -->
  <b-modal
    id="confirm-without-premium"
    ref="confirm-without-premium"
    size="sm"
    :title="$t('booking.positionIsTaken')"
    :ok-title="$t('table.yes')"
    :cancel-title="$t('table.no')"
    auto-focus-button="ok"
    @ok="$emit('confirmWithoutPremium')"
  >
    <p class="my-2">
      {{ $t('booking.positionIsTakenPutInFreePlace') }}
    </p>
  </b-modal>
  <!-- MODAL CONFIRM WITHOUT PREMIUM POSITION -->
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'ModalConfirmWithoutPremiumPosition',
  computed: {
    ...mapGetters({
      isLocale: 'isLocale',
      isThemeHeader: 'isTheme',
    }),
  },
  methods: {
    hideModalConfirmWithoutPremium() {
      this.$refs['confirm-without-premium'].hide();
    },
  },
};
</script>