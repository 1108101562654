<template>
  <div>
    <b-modal id="bid-modal" ref="bid-modal" :title="$t('auction.increaseBid')" @ok="showConfirm" size="sm">
      <div class="d-flex flex-column justify-content-center align-items-center p-2">
        <!-- <number-stepper style="font-size: x-large; width: fit-content; margin: auto" v-model="coefficient" :step="step" :min="minval"></number-stepper> -->
        <div class="d-flex flex-row mt-2 gap-2">
          <button :disabled="coefficient == minval" class="btn btn-outline-danger" @click="coefficient = Number((Number(coefficient) - step).toFixed(2))">
            <b-icon icon="caret-left" />
          </button>
          <!-- <button :disabled="coefficient == minval" class="btn btn-outline-danger btn-sm" size="sm" v-b-modal.bid-confirm>
            {{ $t('table.save') }}
          </button> -->
          <b-input readonly :value="(coefficient * 100).toFixed(0) + '%'" />
          <button class="btn btn-outline-danger" @click="coefficient = (Number(coefficient) + step).toFixed(2)">
            <b-icon icon="caret-right" />
          </button>
        </div>
      </div>
    </b-modal>
    <modal-bid-confirm @increaseBidConfirm="increaseBidConfirm()"></modal-bid-confirm>
  </div>
</template>
<script>
import ModalBidConfirm from '../ModalBidConfirm.vue';
import NumberStepper from '../NumberStepper.vue';
export default {
  name: 'ModalBid',
  components: { NumberStepper, ModalBidConfirm },

  //props: ['bid', 'step'],
  props: {
    bid: {
      type: Number,
      required: true,
    },
    step: {
      type: Number,
      required: true,
    },
    update: {
      type: Boolean,
      required: true,
    },
    blockId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      minval: this.bid,
      coefficient: this.bid,
    };
  },

  watch: {
    update() {
      (this.minval = this.bid), (this.coefficient = this.bid);
    },
  },

  methods: {
    showConfirm() {
      this.$bvModal.show('bid-confirm');
    },

    increaseBidConfirm() {
      this.$emit('increaseBidConfirm', this.coefficient);
    },
  },
};
</script>
